div.dt-rowReorder-float-parent {
  table-layout: fixed;
  outline: 2px solid #0d6efd;
  z-index: 2001;
  position: absolute !important;
  overflow: hidden;
  border-radius: 3px;
}
div.dt-rowReorder-float-parent table.dt-rowReorder-float {
  opacity: 0.9;
  background-color: white;
  margin: 0 !important;
}

div.dt-rowReorder-float-parent.drop-not-allowed {
  cursor: not-allowed;
}

tr.dt-rowReorder-moving {
  outline: 2px solid #888;
  outline-offset: -2px;
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}

table.dataTable td.reorder {
  text-align: center;
  cursor: move;
}

html.dark div.dt-rowReorder-float-parent {
  outline-color: #6ea8fe;
}
html.dark div.dt-rowReorder-float-parent table.dt-rowReorder-float {
  background-color: var(--dt-html-background);
}
html.dark tr.dt-rowReorder-moving {
  outline-color: #aaa;
}

html[data-bs-theme=dark] div.dt-rowReorder-float-parent {
  outline-color: #0d6efd;
}
