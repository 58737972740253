table.dataTable.fixedHeader-floating,
table.dataTable.fixedHeader-locked {
  background-color: white;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

table.dataTable.fixedHeader-locked {
  position: absolute !important;
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}
html[data-bs-theme=dark] table.dataTable.fixedHeader-floating,
html[data-bs-theme=dark] table.dataTable.fixedHeader-locked {
  background-color: var(--bs-body-bg);
}
